<template>
    <h1> Oops! </h1>
    <h1> Experiencing some network issues. </h1>
    <h1><a href='#' @click='$router.go(-1)'> click here </a> to try again.</h1>
</template>



<style scoped>

h1 {
    text-align: center;
}

</style>